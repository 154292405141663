import { Box, IconButton, Tooltip } from "@mui/material";
import { useColorModeContext } from "../context/ColorModeContextProvider";
import { DarkMode, Flare, LightMode } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

const ThemeSwitcher = (props) => {
    const { lightColor = '#00000033', selectedColor = '#00000033' } = props;
    const { toggleColorMode, currentMode } = useColorModeContext();
    const { t } = useTranslation();
    const { theme } = useTheme();
    return (
        <Box sx={{
            borderRadius: 3,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: lightColor,
            p: '3px'
        }}>
            <Tooltip title={t('Light mode')}>
                <IconButton onClick={() => toggleColorMode('light')} sx={{ bgcolor: currentMode === 'light' ? selectedColor : '' }} size="small">
                    <LightMode sx={{ color: currentMode === 'light' ? grey['200'] : lightColor, fontSize: '1rem' }}  />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('System mode')}>
                <IconButton
                    onClick={() => toggleColorMode('auto')}
                    size="small"
                    sx={{ mx: '2px', bgcolor: currentMode === 'auto' ? (theme?.palette?.mode === 'dark' ? selectedColor : selectedColor) : '' }}
                >
                    <Flare sx={{ color: currentMode === 'auto' ? grey['200'] : lightColor, fontSize: '1rem' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('Dark mode')}>
                <IconButton sx={{ bgcolor: currentMode === 'dark' ? selectedColor : '' }} onClick={() => toggleColorMode('dark')} size="small">
                    <DarkMode sx={{ color: currentMode === 'dark' ? grey['200'] : lightColor, fontSize: '1rem' }} />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ThemeSwitcher;