import MainLayout from "./layouts/MainLayout";
import ArticlePage from "./pages/ArticlePage";
import HomePage from "./pages/HomePage";

const routes = () => {
    return [
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { path: '/', element: <HomePage /> },
                { path: '/*?', element: <ArticlePage /> }
            ]
        }
    ];
};

export default routes;