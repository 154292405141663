import { createTheme, responsiveFontSizes } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import { grey } from '@mui/material/colors';

let theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          paddingTop: 8,
          paddingBottom: 8,
          '&.MuiButton-sizeMedium': {
            minHeight: 39,
          },
          '&.MuiButton-sizeLarge': {
            paddingTop: 10,
            paddingBottom: 10,
            '& .MuiButton-startIcon': {
              marginBottom: 0,
            }
          },
          '& .MuiButton-startIcon': {
            marginBottom: 0
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({theme}) => ({
          borderRadius: 12,
          boxShadow: shadows[0]
        })
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:last-child': {
            '& .MuiTableCell-root': {
              borderBottom: '0 none'
            }
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({theme}) => ({
          '&.MuiTableRow-hover:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.02)'
          }
        })
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({theme}) => ({
          fontSize: '1.1rem',
          borderBottomWidth: 1,
          borderBottomColor: theme.palette.mode === 'dark' ? grey[700] : grey[200],
          borderBottomStyle: 'solid'
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({theme}) => ({
          borderTopWidth: 1,
          borderTopColor: theme.palette.mode === 'dark' ? grey[700] : grey[200],
          borderTopStyle: 'solid',
          padding: 16
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 6
        }
      }
    }
  },
  shadows,
  typography,
});
theme = responsiveFontSizes(theme);
export default theme;
