import { Box, Button, Container, Drawer, Fade, Grid, IconButton, Typography } from "@mui/material";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAppConext } from "../context/AppContext";
import LoadingProgress from "../components/LoadingProgress";
import MainAppBar from "./MainAppBar";
import Categories from "../components/kb/Categories";
import { Suspense, lazy, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useColorModeContext } from "../context/ColorModeContextProvider";
import { useTranslation } from "react-i18next";
import ChatWidget from "../components/ChatWidget";
import { School } from "@mui/icons-material";
import ThemeSwitcher from "../components/ThemeSwitcher";
const TicketDialog = lazy(() => import('../components/TicketDialog'));

const MainLayout = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { init, error, errorStatus, treeData, branding, config } = useAppConext();
    
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerAddContent, setDrawerAddContent] = useState(null);
    const { currentMode } = useColorModeContext();

    const handleCategoryChange = (id, route) => {
        setCurrentCategory(id);
        if (route !== undefined) {
            navigate(route, { replace: true });
        }
    };

    const handlePageChange = (id, route) => {
        setDrawerOpen(false);
        setCurrentPage(id);
        if (route !== undefined) {
            navigate(route, { replace: true });
        }
    }

    const categoriesMemo =  useMemo(() => (
        <Categories
            parentSlug={null}
            currentItem={currentCategory}
            currentPage={currentPage}
            onChange={handleCategoryChange}
            onPageChange={handlePageChange}
            items={treeData}
            parentItem={null}
        />
    ), [currentCategory, currentPage, treeData]);

    if (error) {
        return <Typography>{errorStatus}</Typography>;
    }

    return (
        <>
            <Fade in={!init}>
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <LoadingProgress open />
                </Box>
            </Fade>
            {(init) && (
                <Fade in>
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <Helmet>
                            <meta name="theme-color" content={currentMode === 'dark' ? '#1a237e' : '#536dfe'} media="(prefers-color-scheme: dark)" />
                            <meta name="theme-color" content={currentMode === 'dark' ? '#1a237e' : '#536dfe'} media="(prefers-color-scheme: light)" />
                        </Helmet>
                        <MainAppBar onMenu={() => setDrawerOpen(true)} onTicket={() => setTicketDialogOpen(true)} onHome={() => setCurrentCategory(null)} />
                        {(config?.ticketForm !== null && config?.ticketForm !== '' && config?.ticketForm !== undefined) && (
                            <Suspense fallback={<LoadingProgress />}>
                                <TicketDialog form={config?.ticketForm} open={ticketDialogOpen} onClose={() => setTicketDialogOpen(false)} />
                            </Suspense>
                        )}
                        <Drawer PaperProps={{
                            sx: {
                                maxWidth: 260,
                            }
                        }} sx={{
                            '& .MuiButtonBase-root.MuiMenuItem-root': {
                                pr: 2
                            }
                        }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                            <Box sx={{ height: 48, px: 1, py: 0.5, display: 'flex', alignItems: 'center', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'divider' }}>
                                <Box sx={{ mr: 1 }}>
                                    <IconButton sx={{ p: 1, borderRadius: 1 }} onClick={() => {
                                        setCurrentCategory(null);
                                        setDrawerOpen(false);
                                    }} component={NavLink} to="/" centerRipple={false}>
                                        {(config?.logo !== null && config?.logo !== '') ? (
                                            <Box component="img" src={config.logo} sx={{
                                                display: 'block',
                                                width: 32,
                                                height: 32,
                                                objectFit: 'cover'
                                            }} />
                                        ) : (
                                            <School sx={{
                                                width: 24,
                                                height: 24,
                                                color: 'text.primary'
                                            }} />
                                        )}
                                    </IconButton>
                                </Box>
                                <Typography noWrap color="text.contrast" sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: '500',
                                    display: {
                                        xs: 'block',
                                        sm: 'block',
                                        md: 'block'
                                    }
                                }}>
                                    {config?.title}
                                </Typography>
                            </Box>
                            <Box>
                                {categoriesMemo}
                            </Box>
                            <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                                {(config?.ticketForm !== null && config?.ticketForm !== '') && (
                                    <Box>
                                        <Button
                                            onClick={() => {
                                                setTicketDialogOpen(true);
                                                setDrawerOpen(false);
                                            }}
                                            size="small"
                                            color="success"
                                            variant="outlined"
                                        >
                                            {t('Submit a ticket')}
                                        </Button>
                                    </Box>
                                )}
                                {(config?.modeSwitcher === true) && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 2 }}>
                                        <ThemeSwitcher lightColor="primary.main" selectedColor="primary.main" />
                                    </Box>
                                )}
                                {(config?.credits === true) && (
                                    <Box sx={{ bgcolor: 'background.paper', py: 1 }}>
                                        <Box sx={{ px: 1 }}>
                                            <Button
                                                component="a"
                                                target="_blank"
                                                href={branding.brandUrl}
                                                sx={{ textTransform: 'none' }}
                                                fullWidth
                                                startIcon={(
                                                    <Box sx={{
                                                        borderRadius: branding?.defaultBranding ? 5 : 0,
                                                        bgcolor: branding?.defaultBranding ? 'primary.main' : 'transparent',
                                                        p: branding?.defaultBranding ? 0.5 : 0
                                                    }}>
                                                        <img src={branding.logo} style={{
                                                            width: branding?.defaultBranding ? 16 : 24,
                                                            height: branding?.defaultBranding ? 16 : 24,
                                                            display: 'block'
                                                        }} />
                                                    </Box>
                                                )}
                                            >
                                                {t('Powered by {{brand}}', {
                                                    brand: branding.brandName
                                                })}
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Drawer>
                        <Container maxWidth="xl" sx={{
                            minHeight: '100vh',
                            pt: {
                                xs: 7,
                                sm: 7,
                                md: 8
                            },
                            px: {
                                xs: 0,
                                sm: 0,
                                md: 2
                            }
                        }}>
                            <Grid container spacing={0}>
                                <Grid sx={{
                                    height: '100%',
                                    overflow: 'hidden',
                                    borderRightWidth: 1,
                                    borderRightStyle: 'solid',
                                    borderRightColor: 'divider',
                                    position: 'sticky',
                                    top: 64,
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'block'
                                    }
                                }} item sm={3}>
                                    <Box sx={{ position: 'static', top: 0 }}>
                                        <Box sx={{
                                            height: config?.credits === true ? 'calc(100vh - 120px)' : 'calc(100vh - 65px)',
                                            overflow: 'auto'
                                        }}>
                                            {categoriesMemo}
                                        </Box>
                                        {(config?.credits === true) && (
                                            <Box sx={{ bgcolor: 'background.paper', py: 1 }}>
                                                <Box sx={{ px: 1 }}>
                                                    <Button
                                                        component="a"
                                                        target="_blank"
                                                        href={branding.brandUrl}
                                                        sx={{ textTransform: 'none' }}
                                                        fullWidth
                                                        startIcon={(
                                                            <Box sx={{
                                                                borderRadius: branding?.defaultBranding ? 5 : 0,
                                                                bgcolor: branding?.defaultBranding ? 'primary.main' : 'transparent',
                                                                p: branding?.defaultBranding ? 0.5 : 0
                                                            }}>
                                                                <img src={branding.logo} style={{
                                                                    width: branding?.defaultBranding ? 16 : 24,
                                                                    height: branding?.defaultBranding ? 16 : 24,
                                                                    display: 'block'
                                                                }} />
                                                            </Box>
                                                        )}
                                                    >
                                                        {t('Powered by {{brand}}', {
                                                            brand: branding.brandName
                                                        })}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={9} xs={12} sx={{ position: 'relative', height: '100%' }}>
                                    <Outlet context={{
                                        currentCategory: currentCategory,
                                        setCurrentCategory: setCurrentCategory,
                                        setCurrentPage: setCurrentPage,
                                        setDrawerAddContent: setDrawerAddContent
                                    }} />
                                </Grid>
                            </Grid>
                        </Container>
                        {(config?.supportWidget !== null && config?.supportWidget !== '') && (
                            <ChatWidget config={config} />
                        )}
                    </Box>
                </Fade>
            )}
        </>
    );
};

export default MainLayout;