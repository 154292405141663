import { Menu, School, Search } from "@mui/icons-material";
import { AppBar, Box, Button, Container, IconButton, Toolbar, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useAppConext } from "../context/AppContext";
import SearchDialog from "../components/kb/SearchDialog";
import { useEffect, useState } from "react";
import ThemeSwitcher from "../components/ThemeSwitcher";

const MainAppBar = (props) => {
    const { onHome, onTicket, onMenu } = props;
    const [searchOpen, setSearchOpen] = useState(false);
    const { t } = useTranslation();
    const { config } = useAppConext();

    const isMac = () => {
        return navigator.platform.indexOf('Mac') > -1;
    };

    const openSearchDialog = (e) => {
        if (e.key === 'k' && (e.metaKey === true || e.ctrlKey === true)) {
            e.preventDefault();
            setSearchOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', openSearchDialog);
        return () => {
            document.removeEventListener('keydown', openSearchDialog);
        };
    }, []);

    return (
        <AppBar sx={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'divider',
            '& .MuiToolbar-root': {
                px: {
                    xs: 1,
                    sm: 1,
                    md: 2
                },
                height: {
                    xs: '56px !important',
                    sm: '56px !important',
                    md: '64px !important',
                }
            }
            
        }} elevation={0} position="fixed">
            <SearchDialog open={searchOpen} onClose={() => setSearchOpen(false)} />
            <Toolbar>
                <Container maxWidth="xl" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: {
                        xs: 0,
                        sm: 0,
                        md: 0
                    }
                }}>
                    <Box sx={{ mr: 1 }}>
                        <IconButton onClick={onHome} component={NavLink} to="/" sx={{ borderRadius: 1 }} centerRipple={false}>
                            {(config?.logo !== null && config?.logo !== '') ? (
                                <Box component="img" src={config.logo} sx={{
                                    display: 'block',
                                    width: {
                                        xs: 32,
                                        sm: 32,
                                        md: 40
                                    },
                                    height: {
                                        xs: 32,
                                        sm: 32,
                                        md: 40
                                    },
                                    objectFit: 'cover'
                                }} />
                            ) : (
                                <School sx={{
                                    width: {
                                        xs: 24,
                                        sm: 24,
                                        md: 40
                                    },
                                    height: {
                                        xs: 24,
                                        sm: 24,
                                        md: 40
                                    },
                                    color: grey['200']
                                }} />
                            )}
                        </IconButton>
                    </Box>
                    <Typography noWrap color="text.contrast" sx={{
                        fontSize: '1.2rem',
                        fontWeight: '500',
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'block'
                        }
                    }}>
                        {config?.title}
                    </Typography>
                    <Box sx={{ ml: 'auto' }} />
                    {(config?.modeSwitcher === true) && (
                        <Box sx={{
                            mx: 1,
                            flexShrink: 0,
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block'
                            }
                        }}>
                            <ThemeSwitcher lightColor={grey['200']} selectedColor="#00000022" />
                        </Box>
                    )}
                    {(config?.ticketForm !== null && config?.ticketForm !== '') && (
                        <Box sx={{
                            mx: 1,
                            flexShrink: 0,
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block'
                            }
                        }}>
                            <Button
                                onClick={onTicket}
                                size="small"
                                sx={{
                                    borderColor: grey['200'],
                                    color: grey['200'],
                                    '&:hover': {
                                        borderColor: grey['400'],
                                    },
                                    '&:active': {
                                        borderColor: grey['400'],
                                    }
                                }}
                                color="success"
                                variant="outlined"
                            >
                                {t('Submit a ticket')}
                            </Button>
                        </Box>
                    )}
                    <Box sx={{ ml: 1, minWidth: 88, flexShrink: 0 }}>
                        <IconButton onClick={() => setSearchOpen(true)} sx={{
                            borderRadius: 1,
                            display: {
                                xs: 'inline-flex',
                                sm: 'none'
                            },
                        }} centerRipple={false} >
                            <Search sx={{ color: grey['200'] }} />
                        </IconButton>
                        <Button onClick={() => setSearchOpen(true)} variant="outlined" sx={{
                            textTransform: 'none',
                            fontWeight: 'normal',
                            fontSize: '1rem',
                            justifyContent: 'center',
                            bgcolor: 'background.default',
                            borderColor: grey['300'],
                            display: {
                                xs: 'none',
                                sm: 'inline-flex'
                            },
                            '&:hover': {
                                opacity: 0.8,
                                bgcolor: 'background.default',
                                borderColor: grey['400'],
                            }
                        }} startIcon={<Search sx={{ color: 'text.disabled' }} />}>
                            <Typography color="text.secondary" sx={{
                                fontSize: '1.1rem',
                                my: 'auto',
                            }}>
                                {t('Search')}
                            </Typography>
                            <Typography variant="body2" sx={{
                                ml: 4,
                                opacity: 0.8,
                            }} color="text.disabled">
                                {isMac() ? '⌘ + K' : 'Ctrl + K'}
                            </Typography>
                        </Button>
                        <IconButton sx={{
                            ml: 1,
                            borderRadius: 1,
                            display: {
                                xs: 'inline-flex',
                                sm: 'inline-flex',
                                md: 'none'
                            }
                        }} centerRipple={false} onClick={onMenu}>
                            <Menu sx={{ color: grey['200'] }} />
                        </IconButton>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    );
};

export default MainAppBar;