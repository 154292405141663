import { Suspense, lazy, useEffect, useState } from "react";
import $api from "../http";
import { useOutletContext, useParams } from "react-router-dom";
import { useAppConext } from "../context/AppContext";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingProgress from "../components/LoadingProgress";
const Article = lazy(() => import('./Article'));

const ArticlePage = (props) => {
    const [pageInfo, setPageInfo] = useState(null);
    const [blocks, setBlocks] = useState([]);
    const [structure, setStrucutre] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [attachments, setAttachments] = useState([]);
    const [related, setRelated] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [reactions, setReactions] = useState({});
    const [notFound, setNotFound] = useState(false);
    const [childrenPosts, setChildrenPosts] = useState([]);

    const { "*": splat } = useParams();

    const { setCurrentPage } = useOutletContext();
    const { userGuid } = useAppConext();
    const { t } = useTranslation();

    const loadContent = () => {
        setIsLoading(true);
        $api.get(`/kb/page?url=${splat}`, {
            headers: {
                'x-user': userGuid
            }
        })
            .catch((err) => {
                if (err?.response?.status === 404) {
                    setNotFound(true);
                }
            })
            .then((res) => {
                if (res) {
                    setNotFound(false);
                    setBlocks(res.data.blocks);
                    setPageInfo(res.data.pageInfo);
                    setStrucutre(res.data.structure);
                    setAttachments(res.data.attachments);
                    setRelated(res.data.related);
                    setCurrentPage(res.data.pageInfo.guid);
                    setNextPage(res.data.nextPage);
                    setPrevPage(res.data.prevPage);
                    setReactions(res.data.reactions);
                    setChildrenPosts(res.data.children);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadContent();
    }, [splat]);

    return (
        notFound ? (
            <Box sx={{ minHeight: 'calc(100vh - 64px)', display: 'flex' }}>
                <Box sx={{ m: 'auto', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '1.6rem', fontWeight: 'bold' }} color="text.primary">
                        {t('Page not found')}
                    </Typography>
                    <Typography color="text.secondary">
                        {t("The page you are looking for doesn't exist.")}
                    </Typography>
                </Box>
            </Box>
        ) : (
            <Suspense fallback={<LoadingProgress />}>
                <Article
                    isLoading={isLoading}
                    pageInfo={pageInfo}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    structure={structure}
                    attachments={attachments}
                    related={related}
                    blocks={blocks}
                    feedback={reactions}
                    childrenPosts={childrenPosts}
                />
            </Suspense>
        )
        
    );
};

export default ArticlePage;