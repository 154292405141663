import React, { useContext, useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../components/Tools";

export const AppContext = React.createContext();

export const useAppConext = () => {
    return useContext(AppContext);
};

const AppContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [init, setInit] = useState(false);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [branding, setBranding] = useState(null);
    const [homePage, setHomePage] = useState({});
    const [config, setConfig] = useState({});
    const [userGuid, setUserGuid] = useState(null);

    const { i18n } = useTranslation();

    const bootstrap = () => {
        $api.get('/kb/bootstrap', {
            headers: {
                'x-user': getGlobalGuid()
            }
        })
            .catch((err) => {
                setInit(false);
                setErrorStatus(err?.response?.status || 500);
                setError(true);
            })
            .then((res) => {
                if (res) {
                    setTreeData(res.data.treeData);
                    setBranding(res.data.branding);
                    setError(false);
                    setErrorStatus(null);
                    if (res.data.currentPage !== null) {
                        setHomePage(res.data.currentPage);
                    }
                    setConfig(res.data.config);
                    if (res.data.config.lang !== 'auto' && !isEmpty(res.data.config.lang)) {
                        i18n.changeLanguage(res.data.config.lang);
                    }
                    setUserGuid(res.data.user_guid);
                    
                    setTimeout(() => {
                        setInit(true);
                    }, 100);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        if (getGlobalGuid() !== userGuid) {
            if (userGuid !== null && userGuid !== undefined) {
                localStorage.setItem('_anc_global', userGuid);
            }
        }
    }, [userGuid]);

    useEffect(() => {
        setUserGuid(getGlobalGuid());
        bootstrap();
    }, []);

    const getGlobalGuid = () => {
        return localStorage.getItem('_anc_global');
    };

    return (
        <AppContext.Provider value={{
            init,
            isLoading,
            error,
            errorStatus,
            treeData,
            branding,
            homePage,
            config,
            userGuid
        }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;