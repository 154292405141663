import { useEffect } from "react";
import useScript from "./useScript";
import { useLocation } from "react-router-dom";

const ChatWidget = (props) => {
    const { config } = props;
    const l = useLocation();
    const [loaded] = useScript(`${process.env.REACT_APP_API_URL}widget/${config?.supportWidget}/livechat-js`, {
        id: 'livechat-bundle'
    }, true);

    useEffect(() => {
        if (window.anychat !== undefined) {
            window.anychat.setCurrentLocation(document.location);
        }
    }, [loaded, l]);

    return (<></>);
};

export default ChatWidget;