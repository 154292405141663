import { useTranslation } from "react-i18next";
import PageMeta from "../components/PageMeta";
import { useAppConext } from "../context/AppContext";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Suspense, lazy } from "react";
import LoadingProgress from "../components/LoadingProgress";
const Article = lazy(() => import('./Article'));

const HomePage = () => {
    const { homePage } = useAppConext();
    const { t } = useTranslation();
    const { setCurrentPage } = useOutletContext();

    useEffect(() => {
        if (homePage !== undefined && homePage !== null) {
            setCurrentPage(homePage.pageInfo.guid);
        }
    }, [homePage]);

    if (homePage === undefined || homePage === null) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 'calc(100vh - 66px)', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '1.2rem' }}>
                    {t('No articles yet')}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <PageMeta title={t('Knowledge base')} />
            <Suspense fallback={<LoadingProgress />}>
                <Article
                    isLoading={false}
                    pageInfo={homePage.pageInfo}
                    prevPage={homePage.prevPage}
                    nextPage={homePage.nextPage}
                    structure={homePage.structure}
                    attachments={homePage.attachments}
                    related={homePage.related}
                    blocks={homePage.blocks}
                    feedback={homePage.reactions}
                    childrenPosts={[]}
                />
            </Suspense>
        </>
    );
};

export default HomePage;