import { colors } from '@mui/material';

export const lightPallette = {
    background: {
        default: '#F4F6F8',
        paper: colors.common.white
    },
    navbar: {
        main: colors.indigo.A200,
        scrolled: '#536dfeDD',
        contrastText: colors.common.white
    },
    primary: {
        contrastText: '#ffffff',
        main: colors.indigo.A200,
        active: colors.indigo.A200
    },
    error: {
        contrastText: '#ffffff',
        main: '#f50057'
    },
    neutral: {
        main: '#64748B',
        default: '#64748B',
        contrastText: '#fff',
        light: colors.grey[200]
    },
    lightGrid: 'rgba(0, 0, 0, 0.06)',
    text: {
        primary: '#172b4d',
        secondary: '#6b778c'
    }
};

export const darkPallette = {
    background: {
        default: '#0a1424',
        paper: '#0a1929'
    },
    navbar: {
        main: colors.indigo[900],
        scrolled: '#1a237eDD',
        contrastText: colors.grey[400]
    },
    primary: {
        contrastText: '#ffffff',
        main: colors.indigo.A700,
        active: colors.indigo.A700
    },
    error: {
        contrastText: '#ffffff',
        main: '#f50057'
    },
    neutral: {
        main: '#64748B',
        default: '#64748B',
        contrastText: '#fff',
        light: colors.grey[900]
    },
    lightGrid: 'rgba(255, 255, 255, 0.06)',
    text: {
        primary: colors.grey[500],
        secondary: colors.grey[700]
    }
};