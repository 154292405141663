import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}pub/`;

const $api = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

export default $api;