import { Avatar, Box, CircularProgress, Dialog, DialogContent, InputAdornment, InputBase, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import $api from "../../http";
import { HideImage, Search } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useAppConext } from "../../context/AppContext";

let searchTimeout = null;

const SearchDialog = (props) => {
    const { open, onClose } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noItems, setNoItems] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const { userGuid } = useAppConext();
    const inputRef = useRef();
    const theme = useTheme();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const loadContent = () => {
        setLoading(true);
        $api.get(`/kb/search?term=${searchTerm}`, {
            headers: {
                'x-user': userGuid
            }
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                    if (res.data.length > 0) {
                        setNoItems(false);
                    } else {
                        setNoItems(true);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        if (searchTerm !== '') {
            setNoItems(false);
            setLoading(true);
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(loadContent, 600);
        } else {
            setNoItems(false);
            setLoading(false);
            setItems([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (open === true) {
            setItems([]);
            setNoItems(false);
            setLoading(false);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            slotProps={{
                backdrop: {
                    sx: {
                        bgcolor: '#00000088',
                        backdropFilter: 'blur(20px)'
                    }
                }
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'start'
                }
            }}
        >
            <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottomWidth: (items.length > 0 || noItems === true) ? 1 : 0,
                    borderBottomColor: 'divider',
                    borderBottomStyle: 'solid',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    bgcolor: theme?.palette?.mode === 'dark' ? '#FFFFFF00' : '#FFFFFF'
                }}>
                    <Box sx={{ mx: 2 }}>
                        {loading ? <CircularProgress sx={{ display: 'block' }} size={24} /> : <Search sx={{ opacity: 0.7, display: 'block', color: 'text.secondary' }} />}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <InputBase
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={t('Search content')}
                            autoFocus
                            onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                    e.preventDefault();
                                }
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'ArrowUp') {
                                    e.preventDefault();
                                    setSelectedIndex((old) => ((old - 1) < 0) ? (items.length - 1) : old - 1);
                                } else if (e.key === 'ArrowDown') {
                                    e.preventDefault();
                                    setSelectedIndex((old) => (old + 1) > (items.length - 1) ? 0 : old + 1);
                                } else if (e.key === 'Enter') {
                                    if (selectedIndex !== null && items[selectedIndex] !== undefined) {
                                        navigate(items[selectedIndex].url, { replace: true });
                                        onClose();
                                    }
                                }
                            }}
                            inputProps={{
                                ref: inputRef,
                                sx: {
                                    fontSize: '1.1rem',
                                    py: 2
                                }
                            }}
                        />
                    </Box>
                </Box>
                {(items.length > 0) ? (
                    <List sx={{  p: 0, m: 0, height: '100%', overflow: 'auto' }}>
                        {(items.map((i, idx) => (
                            <ListItemButton selected={idx === selectedIndex} onClick={onClose} component={NavLink} to={i.url} sx={{ alignItems: 'start' }}>
                                {(i.post_image !== null) && (
                                    <ListItemAvatar sx={{ mt: 0.5 }}>
                                        <Avatar src={i.post_image} variant="rounded">
                                            <HideImage />
                                        </Avatar>
                                    </ListItemAvatar>
                                )}
                                <ListItemText
                                    sx={{ p: 0, m: 0 }}
                                    primary={i.title}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: '1.2rem'
                                        }
                                    }}
                                    secondaryTypographyProps={{
                                        sx: {
                                            whiteSpace: 'break-spaces',
                                            maxHeight: 120,
                                            overflow: 'hidden'
                                        }
                                    }}
                                    secondary={i.clean_content}
                                />
                            </ListItemButton>
                        )))}
                    </List>
                ) : (
                    (noItems === true) && (
                        <Typography sx={{ color: 'text.secondary', textAlign: 'center', py: 3 }}>
                            {t('No results for "{{term}}"', {
                                term: searchTerm
                            })}
                        </Typography>
                    )
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SearchDialog;