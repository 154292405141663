import './App.css';
import { StyledEngineProvider } from '@mui/material';
import AppContextProvider from './context/AppContext';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import ColorModeContextProvider from './context/ColorModeContextProvider';
import './i18n';

const Routes = () => {
  const routing = useRoutes(routes());
  return routing;
};

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <AppContextProvider>
        <ColorModeContextProvider>
          <Routes />
        </ColorModeContextProvider>
      </AppContextProvider>
    </StyledEngineProvider>
  );
}

export default App;
