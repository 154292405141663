import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { useState } from "react";

const Categories = (props) => {
    const { currentPage, parentItem, onPageChange, items, level = 0, parentSlug = null } = props;
    const [open, setOpen] = useState([]);

    const handleOpenCat = (id) => {
        setOpen((old) => {
            if (old.includes(id)) {
                return old;
            }
            return [
                ...old,
                id
            ];
        });
    };

    const handleToggleCat = (id) => {
        setOpen((old) => {
            if (old.includes(id)) {
                return old.filter((i) => i !== id);
            }
            return [
                ...old,
                id
            ];
        });
    };

    return (
        items.map((item) => (
            (item.children?.length > 0) ? (
                <React.Fragment key={item.guid}>
                    <MenuItem selected={currentPage === item.guid} onClick={() => {
                        handleOpenCat(item.guid);
                        onPageChange(item.guid, item.url);
                    }} sx={{
                        ml: '-1px',
                        my: '1px',
                        overflow: 'hidden',
                        pr: 1,
                        borderLeftWidth: 1,
                        borderLeftColor: 'transparent',
                        borderLeftStyle: 'solid',
                        '&.Mui-selected': {
                            borderLeftColor: level === 0 ? 'transparent' : 'primary.main',
                            '& .MuiListItemText-primary': {
                                color: 'primary.main',
                                fontWeight: '500'
                            }
                        }
                     }} key={item.guid}>
                        {(item.post_image !== null) && (
                            <ListItemIcon>
                                <Box sx={{ borderRadius: 0.5, width: 24, height: 24, objectFit: 'cover' }} component="img" src={item.post_image} />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true,
                                color: 'text.primary'
                            }}
                            primary={item.title}
                        />
                        {/*<IconButton size="small" sx={{ p: 0 }} onClick={(e) => {
                            e.stopPropagation();
                            handleToggleCat(item.id);
                        }}>
                            <KeyboardArrowRight sx={{ color: 'text.secondary', transition: '0.1s all', transform: open.includes(item.id) ? 'rotate(90deg)' : 'none' }} />
                        </IconButton>*/}
                    </MenuItem>
                    {/*<Collapse in={open.includes(item.id)}>*/}
                    <Collapse in>
                        <Box sx={{ ml: level + 1, my: 0.5, borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: 'divider' }}>
                            <Categories
                                parentSlug={parentSlug !== null ? `${parentSlug}/${item.slug}` : `${item.slug}`}
                                parentItem={item}
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                                items={item.children}
                                level={level + 1}
                            />
                        </Box>
                    </Collapse>
                </React.Fragment>
            ) : (
                <React.Fragment key={item.guid}>
                    <MenuItem selected={currentPage === item.guid} onClick={() => {
                        onPageChange(item.guid, item.url);
                    }} sx={{
                        ml: '-1px',
                        my: '1px',
                        borderLeftWidth: 1,
                        borderLeftColor: 'transparent',
                        borderLeftStyle: 'solid',
                        '&.Mui-selected': {
                            borderLeftColor: level === 0 ? 'transparent' : 'primary.main',
                            '& .MuiListItemText-primary': {
                                color: 'primary.main',
                                fontWeight: '500'
                            }
                        }
                    }}>
                        {(item.post_image !== null) && (
                            <ListItemIcon>
                                <Box sx={{ borderRadius: 0.5, width: 24, height: 24, objectFit: 'cover' }} component="img" src={item.post_image} />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true,
                                color: 'text.primary'
                            }}
                            primary={item.title}
                        />
                    </MenuItem>
                </React.Fragment>
            )
        ))
    );
};

export default Categories;